// To use this mixin, you have to have afterSelectedStoreUpdate as a method
// in which you included this mixin.
const SelectedStoreWatcher = {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    selectedStore () {
      return this.$store.state?.dataStore?.selectedStore
    }
  },
  watch: {
    selectedStore (newStore, oldStore) {
      if (newStore?.code !== oldStore?.code) this.afterSelectedStoreUpdate()
    }
  },
  methods: {
  }
}

export default SelectedStoreWatcher
