var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Gateway Firmware")) + " ")
      ]),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10 products ",
          attrs: {
            "item-key": "id",
            headers: _vm.GatewayFirmwareListHeaders,
            items: _vm.GatewayFirmwareList,
            "hide-default-footer": true,
            "items-per-page": _vm.GatewayFirmwareList.length,
            "single-select": false,
            "show-select": ""
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c("Gateway-firmware-uploader", {
        attrs: { btnDisabledFileUpload: _vm.btnDisabledFileUpload },
        on: { initGatewayFirmware: _vm.initGatewayFirmware }
      }),
      _c(
        "v-btn",
        {
          staticClass: "btn mt-10 ",
          staticStyle: { "margin-left": "4px" },
          attrs: {
            text: "",
            icon: "",
            disabled: _vm.btnDisabledDelete || _vm.btnDisabledFileUpload
          },
          on: { click: _vm.deleteFirmware }
        },
        [_vm._v(_vm._s(_vm.$t("Delete")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }