var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "800" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn mt-10",
                      attrs: { text: "", disabled: _vm.btnDisabledFileUpload }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("File Upload")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.fileUploadVisible,
        callback: function($$v) {
          _vm.fileUploadVisible = $$v
        },
        expression: "fileUploadVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-product" }),
              _vm._v(_vm._s(_vm.$t("File Upload")) + " ")
            ])
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticStyle: { width: "600px" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-file-input", {
                          staticClass: "form-file",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            "prepend-icon": "",
                            "truncate-length": "130",
                            solo: "",
                            flat: "",
                            accept: ".tar.gz",
                            placeholder: "" + _vm.$t("Select file")
                          },
                          on: { keydown: _vm.clearTabindex },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c("img", {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      src: require("@/assets/img/ico-upload-file.gif")
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.uploadFiles()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Upload")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancelFileUpload()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }