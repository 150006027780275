<template>
  <section>
    <h2 class="page-title-bar">
      <i class="ico ico-system"></i>{{ $t('Gateway    Firmware') }}
    </h2>
    <v-data-table
    v-model="selected"
      item-key="id"
      :headers="GatewayFirmwareListHeaders"
      :items="GatewayFirmwareList"
      :hide-default-footer="true"
      :items-per-page="GatewayFirmwareList.length"
      class="tbl-type01 mt-10 products "
     :single-select="false"
      show-select
    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
    </v-data-table>
    <Gateway-firmware-uploader
      :btnDisabledFileUpload="btnDisabledFileUpload"
      v-on:initGatewayFirmware="initGatewayFirmware"
    />
     <v-btn text icon :disabled="btnDisabledDelete || btnDisabledFileUpload" style="margin-left:4px"  @click="deleteFirmware"   class="btn mt-10 ">{{
          $t('Delete')
        }}</v-btn>
  </section>
</template>

<script>
// import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'

// Components
import GatewayFirmwareUploader from '@/views/Firmware/modal/GatewayFirmwareUploader'
// Mixins
import SelectedStoreWatcher from '@/mixins/SelectedStoreWatcher'

export default {
  mixins: [SelectedStoreWatcher],
  name: 'GatewayFirmware',
  components: {
    GatewayFirmwareUploader
  },

  data () {
    return {
      singleSelect: false,
      btnDisabledAddDelete: false,
      selected: [],

      // SYSTEM / TYPE / VERSION / LAST UPDATE TIME
      GatewayFirmwareListHeaders: [
        {
          text: '#',
          sortable: false,
          align: 'center',
          value: 'ids',
          width: '15%'
        },
        {
          text: this.$t('SYSTEM'),
          sortable: true,
          align: 'start',
          value: 'system',
          width: '20%'
        },
        {
          text: this.$t('TYPE'),
          sortable: true,
          align: 'start',
          value: 'type',
          width: '20%'
        },
        {
          text: this.$t('VERSION'),
          sortable: true,
          align: 'start',
          value: 'version',
          width: '20%'
        },
        {
          text: this.$t('LAST UPDATE TIME'),
          sortable: true,
          align: 'start',
          value: 'lastUpdateTime'
        }
      ],
      GatewayFirmwareList: [],
      btnDisabledFileUpload: false,
      requests: {
        getFirmware: {
          method: 'GET',
          url: '/api/common/otamanager/api/gw/otapackage'
        },
        deleteFirmware: {
          method: 'DELETE',
          url: '/api/common/otamanager/api/gw/deleteGwPackage'
        }
      }
    }
  },
  computed: {
    btnDisabledDelete () {
      return this.btnDisabledAddDelete || this.selected.length < 1
    }
  },
  watch: {},
  mounted () {
    // Select the company or load data.
    EventBus.$emit('enableSelectedStores', true)
    this.initGatewayFirmware()
    // Check what buttons are inaccessable
    this.$store.dispatch('auth/getDisabledBtn', '10300').then(disabled => {
      this.btnDisabledFileUpload = disabled
    })
  },
  methods: {
    // etc
    afterSelectedStoreUpdate () {
      this.initGatewayFirmware()
    },
    async initGatewayFirmware () {
      this.GatewayFirmwareList = this.convertGatewayFirmwareList(
        await this.getGatewayFirmwareList()
      )
    },
    convertGatewayFirmwareList (GatewayFirmwareList) {
      return GatewayFirmwareList.map((GatewayFirmware, ids) => {
        GatewayFirmware.ids = ids + 1
        return GatewayFirmware
      })
    },
    // API
    getGatewayFirmwareList () {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          this.requests.getFirmware.method,
          this.requests.getFirmware.url,
          config
        )
        .then(res => {
          return res.data.registeredGwPakcage ? res.data.registeredGwPakcage : []
        })
        .catch(error => {
          console.debug(`Could not find Gateway firmware. error: ${error}`)
        })
    },
    // delete
    deleteFirmware () {
      const params = {}
      const data = this.selected.map((gatewayid) => gatewayid.id)
      params.idList = data.toString()
      const config = { params }
      this.$utils
        .callAxios(
          this.requests.deleteFirmware.method,
          this.requests.deleteFirmware.url,
          config
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result))
          // EventBus.$emit('messageAlert', 'Delete Gw Package')

          this.initGatewayFirmware()
        })
        .catch(error => {
          console.debug(`Failed to delete gateway. error: ${error}`)
        })
    }
  }

}
</script>

<style></style>
